import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import MaskGroup from '../../assets/images/Mask group.png';
import { Avatar, Skeleton } from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { getStayHistory } from '../../api/tenant';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../common/constant';
import AddressComponent from '../../components/Common/Address';
import { getS3BaseUrl } from '../../helpers/string_helper';

export default function StayHistory() {
  const [isLoading, setIsLoading] = useState(false)
  const [stayHistory, setStayHistory] = useState([]);
  const s3BaseURL = getS3BaseUrl();
  document.title = 'Stay History | DStayZ';

  useEffect(() => {
    fetchStayingHistories()
  }, [])

  const fetchStayingHistories = async () => {
    try {
      setIsLoading(true)
      const response = (await getStayHistory()).data.data
      setStayHistory(response)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className='complain_ticket'>
          <div className='inner_page_header'>
            Stays History
          </div>
          <div className='overall_tickets list-inline'>
            {stayHistory ? (
              stayHistory?.map((item, index) => (
                <div className='mb-4' key={index}>
                  <div className='single_ticket'>
                    <div className='single_ticket_header flex-wrap d-flex justify-content-between align-items-center'>
                      <div className='ticket_id d-flex align-items-center'>
                        <CalendarTodayOutlinedIcon />
                        <div className='mx-2'>Joining Date </div>
                        <b>{moment(item?.joining_date).format(DEFAULT_DATE_FORMAT)}</b>
                        {/* <b>{dayjs(item?.property_name).format('DD-MM-YYYY')}</b> */}
                      </div>
                      <div className="rs_header_right d-flex">
                        <div
                          className={'ticket_id'}
                          // style={{
                          //   paddingLeft: '12px'
                          // }}
                        >
                          <CalendarTodayOutlinedIcon />
                          <span className='mx-2'>
                            Leaving Date
                          </span>
                          <b>{moment(item.leaving_date).format(DEFAULT_DATE_FORMAT)}</b>
                        </div>
                      </div>
                    </div>
                    <div className='single_ticket_body'>
                      <div className='leaving_reason'>
                        <div className='d-flex align-items-center'>
                          <div className='me-3'>
                            <Avatar
                              src={s3BaseURL + item?.property_image?.location}
                              variant='rounded'
                              sx={{ width: 60, height: 56 }}
                            />
                          </div>
                          <div>
                            <div className='responsive_word_break'>Property</div>
                            <div><b>{item.property_name}</b></div>
                          </div>
                        </div>
                        {item?.property_address && (
                          <div className='col-12 mt-4'>
                            <div className='bankAccountDet'>
                              <h5 className='mb-3'>Address</h5>
                            </div>
                            <Row>
                              <AddressComponent address={item.property_address} />
                            </Row>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='single_ticket comment_refund_note'>
                    <div className='single_ticket_body leaving_reason'>
                      <div className='refund_note d-grid'>
                        <div className='first_block'>
                          <div className='responsive_word_break'>Admin Note</div>
                          <div><b>{item?.note ?? 'N/A'}</b></div>
                        </div>
                        <div className='first_block middle_inbetween_block px-2'>
                          <div className='responsive_word_break'>Refund Amount</div>
                          <b className='ticket_price'>{item.refund_amount}</b>
                        </div>
                        <div className='second_block px-2'>
                          <div className='responsive_word_break'>Acknowledgement Status</div>
                          <div className='ticket_status'>
                            {item?.status}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) :
              (isLoading &&
                <Col md={12}>
                  <Row>
                    <Col md={12}>
                      <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                    </Col>
                    <Col md={12}>
                      <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                    </Col>
                    <Col md={12}>
                      <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                    </Col>
                    <Col md={12}>
                      <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                    </Col>
                    <Col md={12}>
                      <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                    </Col>
                  </Row>
                </Col>
              )
            }
          </div>
          {(!stayHistory || (stayHistory?.length <= 0)) && !isLoading && (
            <div className='pg_vacant_request'>
              <img src={MaskGroup} alt={MaskGroup} />
              <div className='pg_vacant_request_content mt-3 text-center'>
                <h5>No Previous Staying History Found!</h5>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
