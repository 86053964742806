/* eslint-disable react/react-in-jsx-scope */
import femaleIcon from "../../assets/images/female 1.svg"
import male from "../../assets/images/male 1.svg"
import BothGender from "../../assets/images/both 1.svg"
import { GENDER_PREFERENCE } from "../../common/constant";


export const selectGenderPreferenceIcon = (item) => {
    switch (item) {
        case GENDER_PREFERENCE.BOYS_PG:
            return <img
                style={{
                    // marginTop: "0.2em",
                    backgroundColor: '#343747',
                    // marginRight: '6px'
                }}
                src={male}
                alt="male"
                height="15px"
                width="15px"
            />
        case GENDER_PREFERENCE.GIRLS_PG:
            return <img
                style={{
                    // marginTop: "0.2em",
                    backgroundColor: '#343747',
                    // marginRight: '6px'
                }}
                src={femaleIcon}
                alt="female"
                height="15px"
                width="15px"
            />
        default:
            return <img
                style={{
                    // marginTop: "0.2em",
                    backgroundColor: '#343747',
                    // marginRight: '6px'
                }}
                src={BothGender}
                alt="both"
                height="15px"
                width="15px"
            />
    }
};
