import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import image1 from "../../assets/images/appartment.png";
import { Avatar } from '@mui/material';
import { getS3BaseUrl } from '../../helpers/string_helper';

export default function SingleTicket({ data, onClose }) {
	const s3BaseUrl = getS3BaseUrl();

	return (
		<React.Fragment>
			<Dialog
				open={!!data}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					sx: { width: "560px", borderRadius: "10px", overflow: "hidden" },
				}}
				className='ticket_detail_modal'
			>
				<DialogTitle className='ticket_detail_header d-flex justify-content-between'>
					<div>
						<small>Attachments</small>
						{/* <small>Attachments</small> */}
						<h4 className='ticket_id'>Ticket Id: <span>#{data?.id} {data?.subject}</span></h4>
					</div>
					<CancelOutlinedIcon onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
				</DialogTitle>
				<DialogContent>

					{data?.documents?.length > 0 &&
						<div className='attachments'>
							<div className='allAttachments d-flex justify-content-between'>
								{data?.documents?.map((doc, index) => (
									<div className="single_attachment" style={{ textAlign: 'center' }} key={index}>
										<Avatar
											sizes='medium'
											variant='square'
											src={`${s3BaseUrl}${doc.location}`}
											alt={image1}
											sx={
												{
													width: "72px",
													height: "72px",
												}}
										/>
										<a
											href={`${s3BaseUrl}${doc?.location}`}
											target='_blank'
											download={doc?.name}
											rel='noreferrer'>
											<div className='img_name'>
												{doc.name}
												<DownloadForOfflineIcon
													style={{
														textAlign: 'center',
														fontSize: '20px',
														cursor: 'pointer',
													}}
													fontSize={'inherit'}
													color='error'
												/>
											</div>
										</a>
									</div>
								))}
							</div>
						</div>}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
