import React, { useContext, useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SingleTicket from "../../components/Dashboard/singleTicket";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { rentPaymentHistory } from "../../api/paymentHistory";
import { TenantIdContext } from "../../store/Context/context";
import dayjs from "dayjs";
import moment from "moment";
import { PAYMENT_STATUS, PAYMENT_TYPES } from "../../common/constant";
import { NoDataFound } from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png"
import { BlobProvider } from "@react-pdf/renderer";
import RentSlipPdf from "../../components/Dashboard/RentSlipPdf";
import { getAllGlobalSettings } from "../../api/global_settings";
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function RentPaymentHistory() {
  const [open, setOpen] = React.useState(false);
  const { tenant } = useContext(TenantIdContext);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [limit] = useState(10);
  const [listPaymentHistory, setListPaymentHistory] = useState([]);
  const [platformFee, setPlatformFee] = useState(null);
  // Dummy data array

  const handleClose = () => {
    setOpen(false);
  };

  const getStatusColor = (status) => {
    return status === PAYMENT_STATUS.PAID ? "#008000" : "#EF0107";
  };

  const fetchRentSlipHistory = async (limit, page) => {
    const paramData = {
      limit,
      page,
      tenant_id: Number(tenant?.id),
    };
    try {
      const response = await rentPaymentHistory(paramData);
      // eslint-disable-next-line no-unsafe-optional-chaining
      setListPaymentHistory((old) => [...old, ...response?.data?.data?.data]);
      setMeta(response?.data?.data?.meta);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleTarget = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
   
    if (Math.floor(scrollTop + clientHeight) - Math.floor(scrollHeight) >= 0) {
        
      if (meta?.current_page < meta?.last_page) {
        fetchRentSlipHistory(limit, page + 1);
        setPage((old) => old + 1);
      }
    }
  };
  
  useEffect(() => {
    if (tenant?.id) {
      fetchRentSlipHistory(limit, page);
      fetchGlobalSettings();
    }
  }, [tenant?.id]);


  const fetchGlobalSettings = async () => {
    try {
      const response = (await getAllGlobalSettings({ search: 'platform_fee' })).data
      setPlatformFee(parseInt(response.data[0].payload?.value));
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="page-content">
      <Container className="scroll_rent_history" fluid>
        <div className="complain_ticket rent_history">
          <div className="inner_page_header">Payment History</div>
          <div
            className="scroll_content overall_tickets list-inline"
            onScroll={handleTarget}
          >
            {listPaymentHistory?.length ? (
              listPaymentHistory?.map((data, index) => (
                // <div className='single_ticket' onClick={handleClickOpen} key={index} style={{cursor:'pointer'}}>
                <div key={data?.id} className="single_ticket">
                  <div className="single_ticket_header d-flex justify-content-between align-items-center">
                    <div className="ticket_id">
                      <b>{data?.payment_type === PAYMENT_TYPES.DEPOSIT_RECEIVE ? 'Booking Amount payment' : `Rent of ${moment(data?.payment_date).format("MMMM YYYY")}`}</b>
                      &nbsp;
                      <BlobProvider
                        document={
                          <RentSlipPdf
                            item={data?.pdfData}
                            platformFee={platformFee}
                          />
                        }>
                        {({ url }) => (
                          <Button className="yellow_gradient_btn ms-1 p-1"
                            href={url}
                            target='_blank'
                            rel='noreferrer'>
                            {/* Download rent slip */}
                            {/* <FileDownloadIcon
                              titleAccess='Download Slip'
                              fontSize={'inherit'}
                              color='error'
                              style={{
                                textAlign: 'center',
                                fontSize: '28px',
                                cursor: 'pointer',
                              }}
                            /> */}
                            <span>Download slip</span>
                          </Button>
                        )}
                      </BlobProvider>
                    </div>
                    <div className="ticket_price">
                      <b>₹{data?.total_amount}.00</b>
                    </div>
                  </div>
                  <div
                    className="single_ticket_body single_ticket_four_body d-grid"
                    key={index}
                  >
                    <div className="single_ticket_item create_date payment_date">
                      <div className="responsive_word_break">
                        <CalendarTodayOutlinedIcon /> Payment Date:
                      </div>{" "}
                      <b>{dayjs(data.payment_date).format("DD-MM-YYYY")}</b>
                    </div>
                    <div className="single_ticket_item resolved_by pay_status">
                      <div className="responsive_word_break">
                        <CreditScoreOutlinedIcon />
                        Payment Status:
                      </div>{" "}
                      <b style={{ color: getStatusColor(data.status) }}>
                        {data.status}
                      </b>
                    </div>
                    <div className="single_ticket_item resolved_by transaction_id" style={{ marginRight: '4px' }}>
                      <div className="responsive_word_break">
                        <SwapHorizOutlinedIcon />
                        Transaction Id:
                      </div>{" "}
                      <b>{data.transaction_id}</b>
                    </div>
                    <div
                      className="single_ticket_item resolved_date payment_mode"
                      style={{ textAlign: "right", justifyContent: "start" }}
                    >
                      <div className="responsive_word_break">
                        <PaymentsOutlinedIcon /> Payment Mode:
                      </div>{" "}
                      <b style={{textTransform:'uppercase'}}>{data.payment_mode}</b>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoDataFound text="No payment history to show" icon={NoDataIcon} />
            )}
          </div>
        </div>
      </Container>
      <SingleTicket open={open} onClose={handleClose} />
    </div>
  );
}
