import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { createBankDetails } from '../../api/vacant_request';
import { AsterikLabel } from '../Common/AsterikLabel';
import ConfirmationModal from '../Common/ConfirmationModal';
import { TextField, Card, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { ACTIONS } from '../../common/constant';
import { Row } from 'reactstrap';
import { useProfile } from '../Hooks/UserHooks';

export default function AddBankDetails({ open, onClose, tenant_id, refresh }) {
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const handleOpenConfimationModal = () => setIsConfirmModal(true);
    const handleCloseConfimationModal = () => setIsConfirmModal(false);
    const [isModal, setIsModal] = useState(true);
    const [selectedOption, setSelectedOption] = useState('upi');
    const { user } = useProfile();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            selectedOption: "upi",
            upi_id: '',
            address: user?.address,
            email: user?.email,
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            upi_id: Yup.string().when('selectedOption', {
                is: (data) => data === 'upi',
                then: () => Yup.string().required('Please enter upi id').matches(/^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$/, 'Please enter valid upi id')
            }),
            bank_detail: Yup.object().when('selectedOption', {
                is: (data) => data === 'bank',
                then: () => Yup.object().shape({
                    account_holder_name: Yup.string()
                        .strict(true)
                        .trim('Must not include leading and trailing spaces')
                        .max(100, 'Too Long!')
                        .required('Please enter account holder name'),
                    account_number: Yup.string()
                        .matches(/^\d{9,18}$/, 'Invalid account number')
                        .required('Please enter account number'),
                    bank_name: Yup.string()
                        .strict(true)
                        .trim('Must not include leading and trailing spaces')
                        .max(100, 'Too Long!')
                        .required('Please enter bank name'),
                    ifsc: Yup.string()
                        .strict(true)
                        .trim('Must not include leading and trailing spaces')
                        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
                        .required('Please enter ifsc code'),
                }),
            }),
            address: Yup.string().trim('Must not include leading and trailing spaces')
                .max(100, 'Too Long!')
                .required('Please enter address'),
            email: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .email()
                .typeError('Please Enter Valid Email')
                .required(),
        }),
    });

    const handleChange = (event) => {
        formik.resetForm()
        formik.setFieldValue('selectedOption', event.target.value)
        setSelectedOption(event.target.value);
    };

    const vacantRequestCreateForTenant = async () => {
        try {
            const payload = {
                tenant_id: tenant_id,
                address: formik.values.address,
                email: formik.values.email,
                payment_mode: formik.values.selectedOption,
                ...(formik.values.bank_detail && selectedOption === 'bank' ? {
                    bank_detail: {
                        ...(formik.values.bank_detail.account_holder_name && {
                            account_holder_name: formik.values.bank_detail.account_holder_name,
                        }),
                        ...(formik.values.bank_detail.account_number && {
                            account_number: formik.values.bank_detail.account_number,
                        }),
                        ...(formik.values.bank_detail.ifsc && {
                            ifsc: formik.values.bank_detail.ifsc,
                        }),
                        ...(formik.values.bank_detail.bank_name && {
                            bank_name: formik.values.bank_detail.bank_name,
                        }),
                    },
                } : {
                    upi_id: formik.values.upi_id
                }),
            }
            const response = await createBankDetails(payload)
            if (response.status === 200) {
                toast.success(response.data.message);
                refresh()
                onClose()
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
            toast.error(err.response.data.message);
        }
    }

    return (
        <React.Fragment>
            {isModal ? (
                <Dialog
                    open={open}
                    onClose={onClose}
                    backdrop='static'
                    disableScrollLock={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: { width: "510px", borderRadius: "10px", overflow: "hidden" },
                    }}
                    className='ticket_detail_modal'
                >
                    <DialogTitle className='ticket_detail_header d-flex justify-content-between'>
                        <div>
                            <small>Add Bank Details</small>
                        </div>
                        <CancelOutlinedIcon onClick={onClose} style={{ cursor: "pointer", marginTop: "5px" }} />
                    </DialogTitle>
                    <DialogContent>
                        <div className='d-flex justify-content-center mt-2'>
                            <Card className="bedMatrixOptions" style={{ boxShadow: 'unset', marginLeft: '4px' }}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        value={selectedOption}
                                        onClick={handleChange}
                                    >
                                        <FormControlLabel value="upi" control={<Radio color="default" />} label='UPI' style={{ color: 'black' }} />
                                        <FormControlLabel value="bank" control={<Radio color="default" />} label="BANK Account" style={{ color: 'black' }} />
                                    </RadioGroup>
                                </FormControl>
                            </Card>
                        </div>
                        {selectedOption === 'upi' ? (
                            <div className='reason_box'>
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    label={<div>UPI ID <AsterikLabel /></div>}
                                    name="upi_id"
                                    value={formik.values?.upi_id}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    size="small"
                                    className="w-100"
                                />
                                <div className='d-flex mb-3 mt-1'>
                                    {formik.touched.upi_id && formik.errors.upi_id && (
                                        <span className="text-danger">{formik.errors.upi_id}</span>
                                    )}
                                </div>
                            </div>
                        ) : (
                                <Row>
                                    <div className='col-12 mt-4'></div>
                                    <div className='col-sm-6 mb-3'>
                                        <TextField
                                            size='small'
                                            className='form-control'
                                            type='text'
                                            name='bank_detail.account_holder_name'
                                            // label={<div>Account Holder Name <AsterikLabel /></div>}
                                            label="Account Holder Name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: <AsterikLabel />
                                            }}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.bank_detail?.account_holder_name || ''}
                                        />
                                        <div className='d-flex mt-1'>
                                            {formik.touched?.bank_detail?.account_holder_name && formik.errors.bank_detail?.account_holder_name && (
                                                <span className="text-danger">{formik.errors.bank_detail?.account_holder_name}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mb-3'>
                                        <TextField
                                            size='small'
                                            className='form-control'
                                            type='text'
                                            name='bank_detail.account_number'
                                            label={<div>Account Number <AsterikLabel /></div>}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.bank_detail?.account_number || ''}
                                        />
                                        <div className='d-flex mt-1'>
                                            {formik.touched?.bank_detail?.account_number && formik.errors.bank_detail?.account_number && (
                                                <span className="text-danger">{formik.errors.bank_detail?.account_number}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mb-4'>
                                        <TextField
                                            size='small'
                                            className='form-control'
                                            type='text'
                                            name='bank_detail.ifsc'
                                            label={<div>IFSC <AsterikLabel /></div>}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.bank_detail?.ifsc || ''}
                                        />
                                        <div className='d-flex mt-1'>
                                            {formik.touched?.bank_detail?.ifsc && formik.errors.bank_detail?.ifsc && (
                                                <span className="text-danger">{formik.errors.bank_detail?.ifsc}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-sm-6 mb-4'>
                                        <TextField
                                            size='small'
                                            className='form-control'
                                            type='text'
                                            name='bank_detail.bank_name'
                                            label={<div>Bank Name <AsterikLabel /></div>}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values?.bank_detail?.bank_name || ''}
                                        />
                                        <div className='d-flex mt-1'>
                                            {formik.touched?.bank_detail?.bank_name && formik.errors.bank_detail?.bank_name && (
                                                <span className="text-danger">{formik.errors.bank_detail?.bank_name}</span>
                                            )}
                                        </div>
                                    </div>
                            </Row>
                        )}

                        <Row>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='address'
                                    label={<div>Address <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.address || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.address && formik.errors?.address && (
                                        <span className="text-danger">{formik.errors?.address}</span>
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <TextField
                                    size='small'
                                    className='form-control'
                                    type='text'
                                    name='email'
                                    label={<div>Email <AsterikLabel /></div>}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values?.email || ''}
                                />
                                <div className='d-flex mt-1'>
                                    {formik.touched?.email && formik.errors?.email && (
                                        <span className="text-danger">{formik.errors?.email}</span>
                                    )}
                                </div>
                            </div>
                        </Row>
                        <div className="gen_leave_add_btn text-center">
                            <Button className="yellow_gradient_btn pay_full_amt" disabled={!formik.isValid} onClick={() => {
                                setIsModal(false)
                                handleOpenConfimationModal()
                            }} 
                            style={formik.isValid ? { cursor: 'pointer' } : { cursor: 'not-allowed', backgroundColor: 'gray' }}> Save </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            ) : (
                <ConfirmationModal
                    action={ACTIONS.CREATE}
                    show={isConfirmModal}
                    text="Please check carefully the bank account details before submitting."
                    onCloseClick={() => {
                        setIsModal(true)
                        handleCloseConfimationModal()
                    }}
                    onAcceptClick={vacantRequestCreateForTenant}
                />
            )}
        </React.Fragment>
    );
}
