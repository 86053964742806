import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SingleTicket from '../../components/ComplainTicket/ticketDetails'
import { getAllComplainTickets } from '../../api/complain-tickets';
import { DEFAULT_DATE_FORMAT, DEFAULT_PER_PAGE_VALUE } from '../../common/constant';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactQuill from 'react-quill';
import { CircularProgress } from '@mui/material';
import { NoDataFound } from '../../components/Common/NoDataFound';
import PaginationComponent from '../../components/Common/PaginationComponent';
import NoDataIcon from "../../assets/images/no-data.png"
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Link } from 'react-router-dom';


export default function ComplainTicket() {
  const [open, setOpen] = React.useState(false);
  const [complainTicketList, setComplainTicketList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [update, setUpdate] = useState(0);
  const paramsObject = useRef({
    page: 1,
    limit: DEFAULT_PER_PAGE_VALUE,
  });

  const fetchComplainTickets = async (params) => {
    setIsLoading(true);
    try {
      const response = (await getAllComplainTickets(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      setComplainTicketList(resultData);
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      paramsObject.current = {
        ...paramsObject.current,
        page: data.page,
        limit: rowsPerPage,
      };
    } else {
      paramsObject.current = {
        ...paramsObject.current,
        page: 1,
        limit: data?.limit,
      };
      setUpdate((prev) => prev + 1);
      setRowsPerPage(data?.limit);
    }
    await fetchComplainTickets(paramsObject.current);
  };

  useEffect(() => {
    fetchComplainTickets(paramsObject.current);
  }, []);

  const handleClickOpen = (data) => {
    setOpen(data);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className='complain_ticket'>
          <div className='inner_page_header'>Complain Ticket</div>
          <div className='overall_tickets list-inline'>
            {complainTicketList?.length === 0 && isLoading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {complainTicketList?.length === 0 && !isLoading && (
              <div style={{ textAlign: 'center' }}>
                <NoDataFound text='No complain ticket to show' icon={NoDataIcon} />
              </div>
            )}
            {complainTicketList.map((data, index) => (
              <div key={data.id} className='single_ticket'>
                <div
                  className='single_ticket_header d-flex justify-content-between align-items-center'
                  // onClick={() => handleClickOpen(data)}
                  key={index}
                // style={{ cursor: 'pointer' }}
                >
                  <div className='ticket_id'>Ticket Id <b>#{data?.id}</b>{' '}{data?.subject}</div>
                  <div className='ticket_status'>{data?.status}</div>
                </div>
                <div className='single_message'>
                  <div className='d-flex justify-content-between' key={index}>
                    <ReactQuill
                      value={data?.complain_details}
                      readOnly={true}
                      theme={'bubble'}
                    />

                    <div className='create_date px-2 py-2'>
                      {data?.documents?.length > 0 && (
                        <>
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => handleClickOpen(data)}
                          >
                            View Attachments
                          </Link>
                        </>
                      )}
                    </div>
                  </div>

                  <div className='single_ticket_body'>
                    <div className='create_date'>
                      {data?.note?.description && (
                        <>
                          <span className='responsive_word_break'>
                            <SummarizeOutlinedIcon />
                            Admin&apos;s Note:
                          </span>
                          <b>{data?.note?.description}</b>
                        </>
                      )}
                    </div>
                  </div>

                  <div className='single_ticket_body d-flex justify-content-between' key={index}>
                    <div className='create_date'>
                      <div className='responsive_word_break'><CalendarTodayOutlinedIcon /> Create Date:</div> <b>{moment(data?.created_at).isValid() ? moment(data?.created_at).format(DEFAULT_DATE_FORMAT) : 'N/A'}</b>
                    </div>
                    <div className='resolved_by'>
                      {(data?.resolved_at || data?.declined_at) &&
                        (<div className='responsive_word_break'><PersonOutlineOutlinedIcon />{`${data?.declined_at ? 'Declined by: ' : 'Resolved by: '}`}
                          <b>{data?.resolved_by?.name ?? 'N/A'}</b>
                        </div>)}
                      {(data?.resolved_by?.name && !(data?.resolved_at || data?.declined_at)) &&
                        (<div className='responsive_word_break'><PersonOutlineOutlinedIcon />Handled by: &nbsp;
                          <b>{data?.resolved_by?.name ?? 'N/A'}</b>
                        </div>)}
                    </div>

                    <div className='resolved_date' >
                      {data?.resolved_at &&
                        (<span style={{ textAlign: 'right', justifyContent: 'end' }}>
                          <span className='responsive_word_break'><CalendarTodayOutlinedIcon />Resolved Date:</span><b>{moment(data?.resolved_at).isValid() ? moment(data?.resolved_at).format(DEFAULT_DATE_FORMAT) : 'N/A'}</b>
                        </span >)}
                      {data?.declined_at &&
                        (<span style={{ textAlign: 'right', justifyContent: 'end' }}>
                          <span className='responsive_word_break'><CalendarTodayOutlinedIcon />Declined Date:</span><b>{moment(data?.declined_at).isValid() ? moment(data?.declined_at).format(DEFAULT_DATE_FORMAT) : 'N/A'}</b>
                        </span>)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {complainTicketList?.length > 10 && (
          <div>
            <div className='tabsBody'>
              <Row>
                <Col className='col-12'>
                  <Card className='table_card_holder mt-2'>
                    <CardBody>
                      <div className='numbers_pagination_holder'>
                        <PaginationComponent
                          paginationMeta={paginationMeta}
                          handleChangePagination={paginationModelMethod}
                          update={update}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Container>
      <SingleTicket data={open} onClose={handleClose} />
    </div>
  );
}
