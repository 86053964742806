function useChannel(channelName, eventName, callback, pusher){
    if (channelName && pusher) {
      const channel = pusher.subscribe(channelName)
      channel.bind(eventName, (data) => {
        callback(data)
      })
      return() => channel.unbind(eventName)
    } else {
      pusher?.user?.bind(eventName, (data) => {
        callback(data)
      })
    }
}

export default useChannel