export const ROLES = {
    TENANT: 'Tenant',
    EMPLOYEE: '',
    SUPER_ADMIN: 'Super Admin',
    OWNER: 'Owner',
    ADMIN: 'Admin',
    PROPERTY_MANAGER: 'Property Manager',
    PROSPECTING_OWNER: 'Prospecting Owner',
}

export const ACTIVE_STATUS = {
    ACTIVE: 'Active',
    IN_ACTIVE: 'Inactive'
}

export const NOTIFICATION_EVENTS = {
    TENANT_ASSIGNED_TO_BED_BY_ADMIN: {
        event: 'tenant_assigned_to_bed_by_admin',
        url: '/dashboard'
    },
    PG_LEAVING_REQUEST_BY_TENANT: {
        event: 'pg_leaving_request_by_tenant',
        url: '/dashboard'
    },
    TENANT_EVICTED_BY_ADMIN: {
        event: 'tenant_evicted_by_admin',
        url: '/pg-vacant-request'
    },
    COMPLAIN_STATUS_CHANGE: {
        event: 'complain_status_change',
        url: '/complain-ticket'
    },
    MESSAGE_RECEIVED: {
        event: 'message_received',
        url: '/message'
    },
    RENT_SLIP_PUBLISHED: {
        event: 'rent_slip_published',
        url: '/rent-payment-history'
    },

    VACANT_REQUEST_STATUS_UPDATE: {
        event: 'vacant_request_status_update',
        url: '/pg-vacant-request'
    },
    ADMIN_REPLY_TO_REVIEW_TENANT_PORTAL: {
        event: 'admin_reply_to_review_tenant_portal',
        url: '/dashboard'
    },
    OWNER_REPLY_TO_REVIEW_TENANT_PORTAL: {
        event: 'owner_reply_to_review_tenant_portal',
        url: '/dashboard'
    },
    DEPOSIT_REFUND_INITIATE: {
        event: 'deposit_return_initiate',
        url: '/notifications'
      },
    CANCEL_BOOKING_PAYOUT: {
        event: 'cancel_booking_payout',
        url: '/notifications'
      },    

    // Events without sender.
    RENT_REMINDER: {
        event: 'rent_reminder',
        url: '/notifcations'
    },
    NEW_TENANT_ALERT: {
        event: 'new_tenant_alert',
        url: '/notifcations'
    },
}

export const SYSTEM_EVENTS = {
    LOGOUT_USER: 'logout_user',
  };
  