import Pusher from 'pusher-js'
const { REACT_APP_PUSHER_API_KEY: key, REACT_APP_PUSHER_CLUSTER: cluster, REACT_APP_API_URL: baseURl } = process.env
const pusherInstance = () => {
  const token = localStorage.getItem("token-tenant");
  let pusher = null;
  if (token && key && cluster) {
    try {
      pusher = new Pusher(key, {
        cluster,
        userAuthentication: {
          endpoint: baseURl + '/auth/pusher-user',
          headers: {
            Authorization: token
          }
        },
        autoReconnect: true,
        maxReconnectAttempts: 10,
        timeout: 30000
      })
      pusher.signin()
      return pusher;
    } catch (_e) {
      console.error('Failed to authenticate pusher user.')
    }
  }
}
export default pusherInstance
